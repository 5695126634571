.carouselImage {
    /* min-width: 60%; */
    width: 100%;
    height: auto;
    margin: auto;
    position: relative;
    /* bottom: 20%; */
}

.imagecontainer {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 100px;
}

.cards3 {
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3%;
    background-color: #e2fab6;
}

@media only screen and (max-width: 1000px) {


.buttonAlign2 {
    max-height: 900px;
    max-width: 900px;
    margin: 20px 20px 10px 20px;
}
.title{
    font-family: Tahoma;
    letter-spacing: 0.1em;
    font-size: 20px;
    }

}


@media only screen and (min-width: 1000px) {


    .buttonAlign2 {
        max-height: 900px;
        max-width: 900px;
        margin: auto;
        margin-top: 60px;
    }
    .title{
        font-family: Tahoma;
        letter-spacing: 0.1em;
        margin-bottom: 10px;
        }

    
    }