/* body {
    background-color:rgba(42, 60, 67, 0.72);
    background-color: #c8f6b6;
    background-image: url("./assets/bc.jpg")
  
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
