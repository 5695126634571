.foot-font{
    color: #c20346;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    opacity:0.85;
    font-size: 15px;
    font-weight: bold;

}

.foot-font:hover{
    color:rgb(255, 72, 112);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    opacity:0.85;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;

}

#copy{
    color: rgb(37, 27, 27);
}
