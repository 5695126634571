
.title{
    font-family: Tahoma;
    letter-spacing: 0.1em;
    }

.body {
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 10px;
    padding-bottom: 10%;
    text-align: center;
    text-align: center;
    }

    
    @media only screen and (min-width: 1000px) {
    .content{
        margin-left: auto;
        margin-right: auto;
        font-weight: 700;
        font-family: Tahoma;
        padding-left: 15%;
        padding-right: 15%;
        font-size: 20px;
      }
      .title{
        font-family: Tahoma;
        letter-spacing: 0.1em;
        }

        .title1{
            font-family: Tahoma;
            letter-spacing: 0.1em;
            }
        .homepic{
            margin-right: auto;
            margin-left: auto;
            border-radius: 15px;
        }

        .cusion{
            padding-left: 50px;
            padding-right: 50px;
        }

    }
    
  
    
      @media only screen and (max-width: 999px) {
    
        .content{
          margin-left: auto;
          margin-right: auto;
          font-weight: 700;
          font-family: Tahoma;
        }
    
        .title{
        font-family: Tahoma;
        letter-spacing: 0.1em;
        font-size: 20px;
        }
    
        .title1{
            font-family: Tahoma;
            letter-spacing: 0.1em;
            font-size: 25px;
            }
        .homepic{
            border-radius: 15px;
            width: 100%;
            height: 100px;
        }
    
      }