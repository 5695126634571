ul li::marker {
    color: red;
    font-size: 1.3em;
    /* margin-left: "500px"; */
    /* margin-right: "50px"; */
    /* text-align: center; */

  }





.title{
    font-family: Tahoma;
    letter-spacing: 0.1em;
    }

.body {
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 10px;
    padding-bottom: 10%;
    text-align: center;
    text-align: center;
    background-color: #e2fab6;
    }

    
    @media only screen and (min-width: 1000px) {
    .content{
        margin-left: auto;
        margin-right: auto;
        font-weight: 700;
        font-family: Tahoma;
        padding-left: 15%;
        padding-right: 15%;
        font-size: 20px;
      }
      .title{
        font-family: Tahoma;
        letter-spacing: 0.1em;
        }

        .title1{
            font-family: Tahoma;
            letter-spacing: 0.1em;
            }
        .homepic{
            margin-right: auto;
            margin-left: auto;
            border-radius: 15px;
        }

        .cusion{
            padding-left: 50px;
            padding-right: 50px;
        }

        .listpad{
            padding-left: 150px;
            padding-right: 150px;

        }

        .fam1 {
            /* text-indent: -30px; */
            font-weight: 700;
            font-family: Tahoma;
            font-size: 20px;
            text-align: left;
            margin-bottom: 20px;
        } 
        
        .fam2 {
            /* text-indent: -10px; */
            font-weight: 700;
            font-family: Tahoma;
            font-size: 20px;
            text-align: left;
            margin-bottom: 50px;
        } 
        
        .fam3 {
            /* text-indent: -10px; */
            font-weight: 700;
            font-family: Tahoma;
            font-size: 20px;
            text-align: left;
            margin-bottom: 20px;
        } 
        
        .fam4 {
            font-weight: 700;
            font-family: Tahoma;
            font-size: 20px;
            text-align: left;
            margin-bottom: 20px;
        } 

        .goal{
            font-family: Tahoma;
            letter-spacing: 0.1em;
            font-size: 28px;
            text-align: left;
            padding-left: 170px;
            padding-right: 170px;
         }

    }
    
  
    
      @media only screen and (max-width: 999px) {
    
        .content{
          margin-left: auto;
          margin-right: auto;
          font-weight: 700;
          font-family: Tahoma;
          font-size: 15px;
          text-align: center;
        }
    
        .title{
        font-family: Tahoma;
        letter-spacing: 0.1em;
        font-size: 20px;
        }
    
        .title1{
            font-family: Tahoma;
            letter-spacing: 0.1em;
            font-size: 25px;
            }
        .homepic{
            border-radius: 15px;
            width: 100%;
            height: 100px;
        }
        .fam1 {
            /* text-indent: -30px; */
            font-weight: 700;
            font-family: Tahoma;
            font-size: 15px;
            text-align: left;
            margin-bottom: 20px;
        } 
        
        .fam2 {
            /* text-indent: -10px; */
            font-weight: 700;
            font-family: Tahoma;
            font-size: 15px;
            text-align: left;
            margin-bottom: 50px;
        } 
        
        .fam3 {
            /* text-indent: -10px; */
            font-weight: 700;
            font-family: Tahoma;
            font-size: 15px;
            text-align: left;
            margin-bottom: 20px;
        } 
        
        .fam4 {
            font-weight: 700;
            font-family: Tahoma;
            font-size: 15px;
            text-align: left;
            margin-bottom: 20px;
        } 
        .goal{
            font-family: Tahoma;
            letter-spacing: 0.1em;
            font-size: 17px;
            }
    
      }