.color-nav {
    /* background-color: #2a3c434f; */
    /* background-color: #cec7c7; */
    /* -webkit-box-shadow: 0px 4px 15px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 15px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 15px 0px rgba(0,0,0,0.75) */
    box-shadow: none;
    border: 0;

}
.nav-text {
    font-size: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
} 
.nav-font {
    color: #D60B52;
    /* font-size: 20px; */
    /* font-family: Roboto; */
    /* font-style: normal; */
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    margin-top: 7px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-right: 10px;
    opacity:0.85;
    letter-spacing: 2px;
}

.navbar{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
     box-shadow: none;
     text-shadow: none;
}

.nav-font:hover {
    color:rgb(255, 149, 172);
    opacity:1;
    text-decoration: none;
    transition: all 0.5s ease-in-out;

}
#logo-nav {
    width: 450px;
}
@media only screen and (orientation:portrait){
    .nav-font {
        color: #c20346;
        font-size: 20px;
        line-height: 35px;
        margin-top: 7px;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        opacity:0.85;
    }
  .nav-font {
    margin-left: 20px;
    }
    .nav-size {
        margin-right: 5px;
    }
    /* .navDropDown {
        margin-left: -50px;
    } */

    #logo-nav {
        width: 200px;
    }

}

@media screen and (min-width: 769px) {
    .nav-font {
        margin-left: 20px;
    }
    .nav-size {
        margin-left: 100px;
    }
  }

